import { createSignal } from "solid-js";
import InputLabel from "@suid/material/InputLabel";
import {MenuItem, ListItemText } from "@suid/material";
import Select, { SelectChangeEvent } from "@suid/material/Select";
import { getText } from '../SmallComponents/Translate'
 
interface TeamMembersListProps {
    id: string;
    label: string;
    defaultValue?: string;
    value?: string;

    onChange?: (value: string) => void;
}


const TeamMembersList = (props: TeamMembersListProps) => {

    const txtTeammember = getText("smallcomponent", "teammembers")
    const lLabel = (props.label ? props.label : txtTeammember())
    const lId = (props.id ? props.id : "teamMembersListId")
    const lDefaultValue = (props.defaultValue ? props.defaultValue : "Bob Mandly")

    const [users, setUsers] = createSignal(['Bob Mandly', 'Ninna Therkelsen', 'Boddy Olsen', 'Thy Illse'])
    const [activeUser, setActiveUser] = createSignal(lDefaultValue)


    const handleChangeTeamMember = (event: SelectChangeEvent ) => {
        // Call the function passed from the parent component
        const newValue = event.target.value;
        setActiveUser(newValue)
        props.onChange?.(newValue);
    };

    return (
        <>

            <Select
                value={activeUser()}
                defaultValue={activeUser()}
                label={lLabel}
                id={lId}
                variant="outlined"
                size="small"
                onChange={handleChangeTeamMember}
            >
                {users().map((option, index) => (
                    <MenuItem
                        value={option}
                    >
                        <ListItemText>
                            {option}
                        </ListItemText>
                    </MenuItem>
                ))}

            </Select>
        </>
    )
}
export { TeamMembersList }